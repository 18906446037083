define("discourse/plugins/discourse-animated-avatars/discourse/templates/components/user-profile-avatar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="user-profile-avatar">
    {{animated-bound-avatar @user "huge"}}
    <UserAvatarFlair @user={{@user}} />
    <div>
      <PluginOutlet
        @name="user-profile-avatar-flair"
        @connectorTagName="div"
        @outletArgs={{hash model=@user}}
      />
    </div>
  </div>
  */
  {
    "id": "/7v0xPOb",
    "block": "[[[10,0],[14,0,\"user-profile-avatar\"],[12],[1,\"\\n  \"],[1,[28,[35,1],[[30,1],\"huge\"],null]],[1,\"\\n  \"],[8,[39,2],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"user-profile-avatar-flair\",\"div\",[28,[37,4],null,[[\"model\"],[[30,1]]]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@user\"],false,[\"div\",\"animated-bound-avatar\",\"user-avatar-flair\",\"plugin-outlet\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-animated-avatars/discourse/templates/components/user-profile-avatar.hbs",
    "isStrictMode": false
  });
});